<template>
  <div>
    <el-popconfirm
      v-if="row.info.disabled && $canAndRulesPass(user, 'card.enable')"
      confirm-button-text='Да'
      cancel-button-text='нет'
      icon="el-icon-question"
      title="Вы действительно хотите разблокировать карту?"
      @confirm="enableCard(row)"
    >
      <el-button
        style="margin-right: 10px"
        :loading="loadingEnable"
        slot="reference"
        class="table-action-button"
        type="default"
        round
        size="mini"
        icon="fas fa-lock-open"
      >
      </el-button>
    </el-popconfirm>
    <el-popconfirm
      v-if="$canAndRulesPass(row, 'card.delete')"
      confirm-button-text='Да'
      cancel-button-text='нет'
      icon="el-icon-question"
      title="Вы уверены, что хотите удалить карту?"
      @confirm="$emit('delete-card', row)"
    >
      <el-button
        style="margin-right: 10px"
        slot="reference"
        class="table-action-button"
        type="default"
        round
        size="mini"
        icon="far fa-trash-alt"
      >
      </el-button>
    </el-popconfirm>
    <el-tooltip content="Перейти" placement="top" :open-delay="500">
      <el-button
        class="table-action-button"
        type="default"
        round
        size="mini"
        icon="fas fa-external-link-alt"
        @click="$router.push({ name: 'Card', params: { id: row.id } })"
      >
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import requestSender from "@/api/base/requestSender";

export default {
  name: "router-link-to-card-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },
  computed: {
    ...mapGetters(['user']),
  },

  data() {
    return {
      loadingEnable: false,
    }
  },
  mounted() {
  },
  watch: {},
  methods: {
    enableCard(card){
      this.loadingEnable = true;
      requestSender('post', 'cherkizovo-back-integration/enable-card', {
        card_id: card.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Карта успешно разблокирована'
          });
          card.info.disabled = false;
        })
        .finally(()=>{
          this.loadingEnable = false;
        })
    },
  },

}
</script>

<style>

</style>